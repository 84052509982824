/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { InformationCircleIcon, CreditCardIcon, CheckCircleIcon } from "@heroicons/react/24/outline";

/* eslint-disable react/prop-types */
export default function HintCircle() {
    const [showHint, setShowHint] = useState(false);

    const handleCloseClick = () => {
        setShowHint(false);
    };

    const handleHintClick = () => {
        setShowHint(!showHint);
    };

    return (
        <div className="relative">
            <div
                onClick={handleHintClick}
                className="w-[18px] h-[18px] rounded-full bg-slate-200 flex items-center justify-center cursor-pointer text-gray-500 text-xs"
                title="See more"
            >
                ?
            </div>
            {showHint && (
                <div className="fixed left-1/2 transform -translate-x-1/2 top-0 bg-white border p-6 z-50 whitespace-pre-line w-[100vw] h-[100vh] overflow-y-auto">
                    <div className="flex justify-end mb-[30px]">
                        <XCircleIcon className="h-[24px] min-w-[24px] text-gray-400 cursor-pointer" onClick={handleCloseClick} />
                    </div>
                    <div className="max-w-[480px] mx-auto text-left sm:text-center">
                        <h2 className="text-lg font-semibold mb-4">Where to find the UTR Number?</h2>

                        <div className="flex items-center mb-[40px]">
                            <span>You can find your UTR Number in the confirmation message from your bank after you complete the transaction.</span>
                        </div>
                        <h2 className="text-lg font-semibold mb-[24px]">How to complete the payment process:</h2>
                        <div className="flex sm:justify-center sm:text-center mb-[14px]">
                            <span>
                                <strong>1.</strong> Send the specified amount to the provided account.
                            </span>
                        </div>
                        <div className="flex sm:justify-center sm:text-center mb-[14px]">
                            <span>
                                {" "}
                                <strong>2.</strong> Find the UTR Number provided by your bank.
                            </span>
                        </div>
                        <div className="flex sm:justify-center sm:text-center mb-[14px]">
                            <span>
                                {" "}
                                <strong>3.</strong> Enter the UTR Number on this page and click "Continue."
                            </span>
                        </div>

                        <div className="mt-4">
                            <p className="text-sm text-gray-600">
                                If you have any issues, please contact support.{" "}
                                <span onClick={handleCloseClick} className="underline cursor-pointer">
                                    Close
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
