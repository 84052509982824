import axios from "axios";
import u from "./utils.js";

export async function sendRequest(endpoint, body = {}, state) {
    try {
        const url = process.env[`REACT_APP_ENDPOINT_URL`];

        if (!u.isNonEmptyString(state.app_id) || !u.isNonEmptyString(state.auth_token) || !u.isNonEmptyString(url)) throw Error("invalid request params");
        const endpointUrl = `${url}${endpoint}/${state.app_id}`;

        //

        const response = await axios.post(endpointUrl, body, {
            headers: {
                "X-Nitro-Turnstile-Token": state.turnstile_token || "",
                Authorization: `Bearer ${state.auth_token}`,
                "Content-Type": "application/json",
            },
            validateStatus: function (status) {
                return true;
            },
        });

        if (typeof state.setTurnstileToken === "function") {
            console.log("resetting turnstile token after request");
            state.setTurnstileToken(null);
        }

        if (response?.status > 400 || !u.isPositiveNumber(response?.status)) throw Error("endpoint did not return ok status");

        return response?.data || {};
    } catch (error) {
        console.log(error?.message);
        console.log("error with send request");
        state.setError(true);
        return {};
    } finally {
        // state.setLoading(false)
    }
}

/**
 * Gets channel options
 * @returns {Promise}
 */

async function getChannels(state) {
    return await sendRequest("/get-channels", {}, state);
}

/**
 * Gets upload url
 * @returns {Promise}
 */

async function getUploadUrl(state) {
    return await sendRequest("/get-upload-url", {}, state);
}

/**
 * Submit utr
 * @returns {Promise}
 */

async function submitUtr(state, body) {
    return await sendRequest("/submit-deposit", body, state);
    // return { utr_submitted: false }
}

export default {
    getChannels,
    getUploadUrl,
    submitUtr,
};
