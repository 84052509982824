/* eslint-disable react/prop-types */

import React, { useState, useEffect, useCallback } from "react";
import { useAppContext } from "../AppContext.js";
import { DocumentDuplicateIcon, CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";

import u from "../functions/utils.js";
import nitro from "../functions/nitro.js";

import SubmitButton from "./common/SubmitButton.js";
import HintCircle from "./pay/Hint.js";
import PayHeader from "./pay/PayHeader.js";

import { isValidUTR } from "../functions/utr.js";

let usedTokens = [];

function DetailDisplay({ title, text }) {
    const { addToast } = useAppContext();

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text);
        addToast("success", "Copied to clipboard!", 2400);
    };

    return (
        <div className="w-full">
            {" "}
            {/* Ensuring the width is 100% */}
            <div className="block text-sm mb-2 font-semibold leading-6 text-gray-900">{title}</div>
            <div
                onClick={copyToClipboard}
                className={u.classNames(
                    "flex items-center p-4 space-x-2",
                    "group cursor-pointer relative w-full rounded-md min-h-[48px] mb-6",
                    "bg-slate-50",
                    "ring-1 ring-inset ring-gray-300",
                    "hover:ring-blu hover:bg-opacity-5 hover:bg-blu_med"
                )}
            >
                <div>
                    <DocumentDuplicateIcon className="h-4 w-5 sm:w-5 sm:h-5 text-gray-400 group-hover:text-blu" />
                </div>
                <div className="flex-grow">
                    <span className="text-sm text-gray-600 group-hover:text-blu">{text}</span>
                </div>
            </div>
        </div>
    );
}

export default function PayComponent() {
    const { addToast, setError, setLoading, amount, utr, setUtr, app_id, auth_token, turnstile_token, setTurnstile, setTurnstileToken, channel, setPage } =
        useAppContext();

    const [utrValid, setUtrValid] = useState(null);

    const finishSubmission = async () => {
        try {
            setLoading(true);

            const submission = await nitro.submitUtr(
                { setError, setLoading, setTurnstileToken, turnstile_token, auth_token, app_id },
                { channel_id: channel.channel_id, utr_number: utr }
            );

            if (u.isNonEmptyString(submission?.error)) {
                let toastMsg = submission?.error.indexOf("utr_number") > -1 ? "Invalid UTR" : "Something went wrong";
                addToast("error", toastMsg, 3000);
                setLoading(false);
                return;
            }

            if (!u.isNonEmptyString(submission?.status)) {
                addToast("error", "Something went wrong", 3000);
                setLoading(false);
                return;
            }

            setPage("done");
        } catch (error) {
            console.log("error with finish submit", error?.message);
            addToast("error", "Something went wrong");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (turnstile_token) {
            if (usedTokens.indexOf(turnstile_token) === -1) finishSubmission();
            usedTokens.push(turnstile_token);
        }
    }, [turnstile_token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!utrValid) {
                addToast("error", "Invalid UTR", 2000);
                return;
            }
            setLoading(true);

            setTurnstile(true);
        } catch (error) {
            console.log("error with handle submit", error?.message);
            addToast("error", "Something went wrong");
        } finally {
            setLoading(false);
        }
    };

    const handleUtrChange = (e) => {
        const newUtr = e.target.value;
        setUtr(newUtr);
        setUtrValid(isValidUTR(newUtr));
    };

    try {
        return (
            <div className="bg-white sm:px-[4vw] sm:py-[2.4vw] bg-transparent flex justify-center">
                <div className="flex flex-col sm:p-2 bg-white rounded-[12px] sm:ring-1 sm:ring-gray-300 w-full sm:max-w-[540px] min-h-[0px] pb-[40px]">
                    <PayHeader />
                    <div className="p-[4vw] pt-[40px]">
                        <div className="flex flex-col items-center pt-0 w-full max-w-lg mx-auto">
                            <div className="flex flex-col items-center justify-center w-full max-w-[400px] mx-auto">
                                {DetailDisplay({ title: "Account Number", text: channel.bank_account_number })}
                                {DetailDisplay({ title: "Bank Name", text: channel.bank_name })}
                                {DetailDisplay({ title: "Account Holder", text: channel.account_holder })}
                                {DetailDisplay({ title: "IFSC Code", text: channel.bank_ifsc_code })}
                            </div>

                            <div className="w-full text-left max-w-[400px]">
                                <div className="flex items-center justify-left">
                                    <p className="text-sm font-semibold text-gray-900 mr-2">UTR Number</p>
                                    <HintCircle />
                                </div>
                            </div>
                            <div className="w-full relative mt-2 mb-[24px] max-w-[400px]">
                                <input
                                    type="text"
                                    className={u.classNames(
                                        "w-full rounded-md border-1 p-3 text-gray-900 text-center ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:leading-6 focus:outline-none",
                                        utrValid === null ? "ring-gray-300" : utrValid ? "ring-green_ok focus:ring-green_ok" : "ring-red_err focus:ring-red_err"
                                    )}
                                    placeholder="Enter UTR Number"
                                    value={utr}
                                    onChange={handleUtrChange}
                                />
                                {utrValid !== null && (
                                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                                        {utrValid ? (
                                            <CheckCircleIcon className="h-5 w-5 text-green_ok" />
                                        ) : (
                                            <ExclamationCircleIcon className="h-5 w-5 text-red_err" />
                                        )}
                                    </div>
                                )}
                            </div>
                            <SubmitButton onClick={handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        );
        // eslint-disable-next-line no-unreachable
    } catch (error) {
        console.log("pay component error");
        console.log(error?.message);
        setError(true);
        return;
    }
}

// 678678678678
